import React from 'react'
import { navigate, graphql, Link } from 'gatsby'

import { isLoggedIn, LoginPath } from '../services/auth'
import { useToggle } from '../hooks'
import GoogleLogo from '../images/googlelogo.png'
import PeopleAlsoAsk from '../components/Search/PeopleAlsoAsk'
import ResultsTabs from '../components/Search/ResultsTabs'

const Search = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  if (typeof window === 'undefined') return null
  if (!isLoggedIn(window)) {
    navigate(LoginPath)
    return null
  }

  const Posts = edges
    .filter(edge => edge.node.frontmatter.path.includes('search'))
    .map(edge => (
      <SearchResult
        key={edge.node.id}
        to={edge.node.frontmatter.path}
        url={edge.node.frontmatter.fakeURL}
        redirect={edge.node.frontmatter.redirect}
        date={edge.node.frontmatter.date}
        description={edge.node.frontmatter.description}
        children={edge.node.frontmatter.title}
      />
    ))

  return (
    <>
      <div style={{ display: 'flex', padding: 30, paddingBottom: 0 }}>
        <span style={{ marginRight: 24, paddingTop: 5 }}>
          <img src={GoogleLogo} style={{ width: 92, height: 30 }} />
        </span>
        <SearchBar />
      </div>
      <ResultsTabs />

      <div style={{ marginLeft: 150, width: 632 }}>
        <p style={{ margin: 0, color: '#777', fontSize: 14 }}>
          About 2,590,000,000 results (0.38 seconds)
        </p>
        <PeopleAlsoAsk />
        {Posts}
      </div>
    </>
  )
}

const SearchBar = () => (
  <span style={styles.searchBar}>
    <input style={styles.searchInput} defaultValue='how to be romantic' />
    <SearchIcon />
  </span>
)

const SearchIcon = () => {
  const [hovered, toggleHover] = useToggle(false)
  const fill = hovered ? '#4285f4' : undefined
  return (
    <span
      style={styles.searchIcon}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <svg
        fill={fill}
        focusable='false'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
      >
        <path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' />
      </svg>
    </span>
  )
}

const SearchResult = props => {
  const [hovered, toggleHover] = useToggle(false)
  const textDecoration = hovered ? 'underline' : 'none'
  const style = { textDecoration, fontSize: 18 }
  const link = props.redirect ? (
    <a
      href={props.redirect}
      target='_blank'
      children={props.children}
      style={style}
    />
  ) : (
    <Link to={props.to} children={props.children} style={style} />
  )
  return (
    <div>
      <h3
        style={{ margin: 0, fontWeight: 'normal' }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        {link}
      </h3>
      <div style={{ color: '#006621', fontSize: 14 }}>{props.url}</div>
      <p style={{ color: '#545454', fontSize: 14, lineHeight: 1.43 }}>
        <span style={{ color: '#777' }}>{props.date}</span> -{' '}
        {props.description}
      </p>
    </div>
  )
}

const styles = {
  searchBar: {
    border: '1px solid #dfe1e5',
    boxShadow: 'none',
    width: 630,
    borderRadius: 24,
    height: 44,
    padding: '5px 4px 0 16px'
  },
  searchInput: {
    border: 'none',
    width: '90%',
    fontSize: 16
  },
  searchIcon: { height: 24, width: 24, float: 'right', margin: '4px 8px' }
}

export default Search

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            fakeURL
            redirect
            description
          }
        }
      }
    }
  }
`
