import React from 'react'
import { useToggle } from '../../hooks'

import accordionItems from './items'

const PeopleAlsoAsk = () => (
  <Container>
    <h2 style={styles.header}>People Also Ask</h2>
    <Accordion />
  </Container>
)

const Container = ({ children }) => (
  <div style={styles.container} children={children} />
)

const Accordion = () =>
  accordionItems.map((item, i) => (
    <AccordionItem key={i} title={item.title} children={item.content} />
  ))

const AccordionItem = ({ title, children }) => {
  const [open, toggleOpen] = useToggle(false)
  return (
    <>
      <div style={styles.accordionItem} onClick={toggleOpen}>
        {title}
        <AccordionArrow onClick={toggleOpen} open={open} />
      </div>
      {open && <div children={children} style={styles.accordionContent} />}
    </>
  )
}

const AccordionArrow = ({ onClick, open }) => (
  <span
    style={{ ...styles.accordionArrow, ...(open && styles.closeArrow) }}
    onClick={onClick}
  >
    <svg
      focusable='false'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
    </svg>
  </span>
)

const styles = {
  accordionItem: {
    lineHeight: 1.2,
    fontSize: 18,
    cursor: 'pointer',
    padding: '8px 40px 8px 16px',
    borderTop: '1px solid #e5e5e5',
    fontFamily: 'arial, sans-serif',
    position: 'relative',
    userSelect: 'none'
  },
  accordionArrow: {
    position: 'absolute',
    right: 15,
    color: '#70757A',
    height: 24,
    width: 24
  },
  closeArrow: {
    transform: 'rotate(180deg)'
  },
  accordionContent: { padding: 16, fontSize: 16 },
  container: {
    border: '1px solid #dfe1e5',
    borderRadius: 8,
    marginTop: 16,
    marginBottom: 16
  },
  header: {
    color: '#808080',
    fontSize: 20,
    padding: '15px 16px 10px',
    fontWeight: 'normal',
    margin: 0
  }
}

export default PeopleAlsoAsk
