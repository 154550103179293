import React from 'react'

const ResultsTabs = () => (
  <div
    style={{
      borderBottom: '1px solid #ebebeb',
      color: '#666',
      fontSize: 13,
      marginBottom: 24
    }}
  >
    <span style={{ ...styles.tab, ...styles.selected }}>All</span>
    <span style={styles.tab}>Videos</span>
    <span style={styles.tab}>News</span>
    <span style={styles.tab}>Images</span>
    <span style={styles.tab}>Shopping</span>
    <span style={styles.tab}>More</span>
  </div>
)

const styles = {
  tab: {
    padding: '28px 16px 12px',
    position: 'relative',
    bottom: 7,
    cursor: 'pointer'
  },
  selected: {
    marginLeft: 150,
    borderBottom: '3px solid #1A73E8',
    color: '#1A73E8',
    fontWeight: 'bold'
  }
}

export default ResultsTabs
