import React from 'react'

import Link from './Link'

const ListItem = ({ children }) => <li style={styles.listItem}>{children}</li>

const styles = {
  listItem: { margin: 0 },
  header: { fontSize: 16, marginBottom: 15 }
}

const AccordionContent = ({ title, items, linkTo, linkTitle }) => (
  <>
    <h3 style={styles.header}>{title}</h3>
    <ol>
      {items.map((item, i) => (
        <ListItem key={i}>{item}</ListItem>
      ))}
    </ol>
    <Link to={linkTo} children={linkTitle} />
  </>
)

const accordionItems = [
  {
    title: 'How can I be romantic to my girlfriend?',
    content: (
      <AccordionContent
        title='How to Be Romantic With Your Girl'
        items={[
          'Surprise her. Catching your girl off guard is a great way to amp up the romance',
          'Give her little love tokens. Give your girl gifts, but make them meaningful',
          'Put thought into your dates',
          'Arrange alone time',
          'Give her your undivided attention',
          'Voice your feelings',
          'Be affectionate'
        ]}
        linkTitle='How to Be Romantic With Your Girl | Dating Tips'
        linkTo='https://datingtips.match.com/romantic-girl-13443379.html'
      />
    )
  },
  {
    title: 'What are some romantic ideas?',
    content: (
      <AccordionContent
        title='In a Love Rut? Try These 50 Romantic Date Ideas'
        items={[
          'Learn to dance. Nothing brings two people together quite like dancing',
          'Rent a bicycle for two',
          'Have drunch',
          'Take a pottery class',
          'Wander a bookstore',
          'Recreate a romantic movie scene',
          'Find a fireplace',
          'Head to the drive-in'
        ]}
        linkTo='https://stylecaster.com/romantic-date-ideas/'
        linkTitle="50 Romantic Date Ideas That Don't Cost a Ton | StyleCaster"
      />
    )
  },
  {
    title: 'How do I show my gf how much I love her?',
    content: (
      <AccordionContent
        title='Method 2 Sweet Surprises'
        items={[
          'Write her a love note',
          "Don't ever forget to say that you love her because she will never forget the moment you explained your love to her",
          'Get in touch with her before bed',
          'Surprise her with a sweet treat',
          'Give her flowers',
          'Give a simple gift',
          'Show affection when she least expects it'
        ]}
        linkTitle='The 3 Best Ways to Show Your Girlfriend How Much You Care'
        linkTo='https://m.wikihow.com/Show-Your-Girlfriend-How-Much-You-Care-(for-Guys)'
      />
    )
  },
  {
    title: 'How can I talk romantic on the phone?',
    content: (
      <>
        <h3 style={styles.header}>
          Method 1 Having Romantic Conversations on the Phone
        </h3>
        <ol>
          <ListItem>
            Tell your partner what you like. Want to romance your partner on the
            phone?
          </ListItem>
          <ListItem>Plan dates that you'll go on</ListItem>
          <ListItem>Talk about great times you've had together</ListItem>
          <ListItem>Fantasize together</ListItem>
          <ListItem>Set regular phone dates</ListItem>
          <ListItem>Do something together on the phone</ListItem>
          <ListItem>Just be together on the phone</ListItem>
        </ol>
        <Link
          to='https://www.wikihow.com/Be-Romantic-on-the-Phone'
          children='How to Be Romantic on the Phone: 13 Steps (with Pictures)'
        />
      </>
    )
  },
  {
    title: 'How do I cuddle with my girlfriend?',
    content: (
      <>
        <h3 style={styles.header}>Part 1 Cuddling with Your Girlfriend</h3>
        <ol>
          <ListItem>
            Slide up to her. If you're both on the couch, don't sit miles apart
            from your lady unless you want her to feel lonely
          </ListItem>
          <ListItem>Let her put her arm around you</ListItem>
          <ListItem>Do the reclining cuddle</ListItem>
          <ListItem>Do the sitting spoon</ListItem>
          <ListItem>Do the "Mama Bear" spoon</ListItem>
          <ListItem>Do the "Papa Bear" spoon </ListItem>
          <ListItem>Avoid a few terrible cuddling moves</ListItem>
        </ol>
        <Link
          to='https://www.wikihow.com/Kiss-and-Cuddle-With-Your-Boyfriend'
          children='How to Kiss and Cuddle With Your Boyfriend: 14 Steps'
        />
      </>
    )
  }
]

export default accordionItems
