import React from 'react'

const Link = ({ to, children }) => (
  <a target='_blank' style={{ textDecoration: 'none' }} href={to}>
    <h3 style={{ fontSize: 18, margin: 0, fontWeight: 'normal' }}>
      {children}
    </h3>
    <div style={{ fontSize: 14, color: '#006621' }}>{to}</div>
  </a>
)

export default Link
